<template>
  <main>
    <div class="formGroup">
      <input
        type="search"
        placeholder="search"
        @keyup.enter="handleSearch"
        @keyup="handleSearch"
      >
    </div>
    <h1
      v-if="!filteredData.length"
      style="text-align: center"
    >
      :^( Nada foi encontrado! Mas você pode tentar de novo!
    </h1>
    <section
      class="grid"
      style="min-height: 100vh"
    >
      <router-link
        v-for="(post, index) in filteredData"
        :key="index"
        :style="`animation-delay: ${index / 6}s`"
        :class="{animated: true, veryFast: true, fadeInRight:true, [colorByCateogory[post.categories[0].slug]]: true}"
        tag="article"
        :to="{name: 'post', params: {slug: post.slug}}"
      >
        <div
          class="image"
          :style="`background-image: url(${
            post.jetpack_featured_media_url ? post.jetpack_featured_media_url :
            post.featured_media ? medias[post.featured_media] : ''
          })`"
        />

        <div class="categories">
          <router-link
            v-for="(category, index) in post.categories"
            :key="category.id"
            class="category"
            :to="{name: 'blog', query: {category: category.slug}}"
            tag="a"
          >
            <img
              v-if="category.id > 1 && index === 0"
              :src="categoryIcons.cone"
              :alt="category.name"
            >
          </router-link>
        </div>

        <div class="postGroup">
          <div
            v-if="post.author"
            class="author"
          >
            <img
              :src="post.author.avatar_urls['96']"
              :title="`Por ${ post.author.name }}`"
              :alt="post.author.name"
            >
          </div>
          <div class="categoryNames">
            <span
              v-for="category in post.categories"
              :key="category.id"
            >
              {{ category.name }}
            </span>
          </div>
          <h2>{{ post.title.rendered }} </h2>
          <p>{{ post.excerpt.rendered | toString }}</p>

          <p class="date">
            {{ post.date | humanizeDate }}
          </p>
        </div>
        <button :class="['button', 'circle', colorByCateogory[post.categories[0].slug]]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74.019"
            height="119.788"
            viewBox="0 0 74.019 119.788"
          >
            <g
              id="down-chevron"
              transform="translate(0 119.788) rotate(-90)"
            >
              <g
                id="Group_219"
                data-name="Group 219"
                transform="translate(0 0)"
              >
                <path
                  id="Path_413"
                  data-name="Path 413"
                  d="M105.663,74.019,59.894,28.25,14.125,74.019,0,59.894,59.894,0l59.894,59.894Z"
                />
              </g>
            </g>
          </svg>
        </button>
      </router-link>
    </section>
  </main>
</template>
<script>
/* eslint-disable global-require,import/no-dynamic-require */
import { mapActions, mapState } from 'vuex';

export default {
    name: 'Blog',
    filters: {
        humanizeDate(value) {
            const date = new Date(value);
            return date.toLocaleDateString('pt-br', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
        },
        toString(value) {
            const p = document.createElement('p');
            p.innerHTML = value;
            return p.innerText;
        },
    },
    data() {
        return {
            fields: {
                _fields: ['author', 'content', 'media', 'jetpack_featured_media_url', 'slug', 'id',
                    'title', 'status', 'type', 'date', 'excerpt', 'featured_media', 'categories', 'tags'],
                status: 'publish',
                page: 1,
                per_page: 10,
            },
            filteredData: [],
        };
    },
    computed: {
        ...mapState('posts', ['posts', 'authors', 'categories', 'medias']),
        ...mapState(['themeColor']),
        colorByCateogory() {
            return {
                'biomassa-biometano': 'green',
                'gas-natural': 'blue',
                'energia-solar': 'yellow',
                'conexao-eletrica': 'blue-grey',
            };
        },
        categoryIcons() {
            return {
                cone: require('@/assets/cone.png'),
            };
        },
    },
    watch: {
        $route(value) {
            this.filterDataByQuery(value.query);
            this.changeMenuVisibility(false);
            this.changeIsBlog(true);
            this.changeThemeColor(value.query.category);
        },
        posts(value) {
            this.filteredData = value;
        },
    },
    created() {
        this.changeLoaderVisibility(true);
        this.getPosts(this.fields).then((posts) => {
            if (this.$route.query.category) {
                this.filterDataByQuery(this.$route.query, posts);
            }
            this.changeLoaderVisibility(false);
        });
    },
    methods: {
        ...mapActions('posts', ['getPosts', 'getPages']),
        ...mapActions(['changeLoaderVisibility', 'changeMenuVisibility', 'changeIsBlog', 'changeThemeColor']),
        handleSearch(event) {
            const { value } = event.currentTarget;
            const filter = this.posts.filter((post) => post.title.rendered.toLowerCase().includes(value.toLowerCase()));
            if (filter) this.filteredData = filter;
            this.filteredData = filter;
        },
        filterDataByQuery(query, posts) {
            const pts = posts || this.posts;
            const filter = pts.filter((post) => {
                const slugs = post.categories.map((c) => c.slug);
                return slugs.includes(query.category);
            });

            if (filter) this.filteredData = query.category ? filter : this.posts;
        },
    },
};
</script>
