<template>
  <div class="blog">
    <blog />
  </div>
</template>

<script>
import Blog from '@/components/Blog.vue';
import { mapActions } from 'vuex';

export default {
    name: 'PostPage',
    components: {
        Blog,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.changeMenuVisibility(false);
            vm.changeThemeColor(vm.$route.query.category);
        });
    },
    created() {
        this.changeIsBlog(true);
        this.getMedia();
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeIsBlog', 'changeThemeColor']),
        ...mapActions('posts', ['getMedia']),
    },
};
</script>
